h3 {
  color: white;
  text-align: center;
  margin: 1rem;
}
h2 {
  margin: 1rem;
}
p,
li {
  color: aliceblue;
}

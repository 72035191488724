@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
  --font-family: "Light", "Montserrat", sans-serif;

  --gradient2: linear-gradient(89.97deg, #eb1484 1.84%, #c81cc5 102.67%);
  --primary-btn: linear-gradient(
    101.12deg,
    #eb1484 27.35%,
    #c91cc3 99.99%,
    #c81cc5 100%,
    #c81cc5 100%
  );

  --primary-color: #eb1484;
  --secondary-color: #c81cc5;

  --color-bg: #24252d;
  --color-card: #2a2d3a;
  --color-footer: #24252d;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2a2d3a #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--color-card);
  border-radius: 10px;
  border: 3px none #ffffff;
}

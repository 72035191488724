.iframe-container {
  text-align: center;
  width: 100%;
}

.imagesuper {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.para {
  margin: 20px;
  font-size: 20px;
}

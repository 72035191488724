h1 {
  color: white;
  text-align: center;
  margin: 1rem;
}
h2 {
  margin: 1rem;
}
p,
li {
  color: aliceblue;
}

@media screen and (max-width: 550px) {
  h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 400px) {
  h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 400px) {
  .p-container {
    font-size: 16px;
  }

  .p-header1 {
    font-size: 100px !important;
  }
}

.p-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: 800px;
  margin-left: -400px; */
  position: relative;
  /* top: 30%;
  left: 50%; */
  z-index: 999999999999999999 !important;
  height: fit-content;
  width: 100vw;
  overflow: hidden;
  gap: 10px;
  padding: 15px;
}

.p-header1 {
  margin: 0;
  font-size: 150px;
  line-height: 150px;
  font-weight: bold;
}
.p-header2 {
  margin-top: 20px;
  font-size: 30px;
}
